.title {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  background-color: var(--color-base-100);
  padding-top: ($strip-padding * 2) + ($header-height / 2);
  padding-bottom: $strip-padding * 2;
  @include media-breakpoint-up(md) {
    padding-top: ($strip-padding * 3) + ($header-height / 2);
    padding-bottom: $strip-padding * 3;
  }
  p {
    @extend .h3;
  }
  img {
    border-radius: $border-radius;
  }
  .title-icon {
    width: 48px;
    margin-bottom: 10px;
    i {
      font-size: 48px;
      color: var(--color-base-900);
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.menu-main {
  display: none;
  color: inherit;
  @include media-breakpoint-up(md) {
    display: block;
  }
  ul {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      height: inherit;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      &:last-of-type {
        margin: 0;
      }
      a {
        height: inherit;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        display: block;
        padding: 10px 10px 10px 10px;
        text-decoration: none;
        color: inherit;
        &:hover {
          opacity: 0.7;
        }
        @include media-breakpoint-up(lg) {
          font-size: 15px;
          font-weight: 400;
          text-transform: uppercase;
          display: block;
          padding: 10px 13px 10px 13px;
        }
      }
      .dropdown {
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
      }

      // hover state
      &:hover {
        .dropdown {
          height: auto;
          display: block;
          opacity: 1;
          visibility: visible;
          width: 200px;
          left: 50%;
          margin-left: -100px; /* half of width */
          position: absolute;
          box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid var(--color-base-100);
          border-radius: 3px;
          &::after,
          &::before {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid var(--color-base);
            content: "";
            left: 50%;
            top: -8px;
            margin-left: -8px;
            position: absolute;
          }
          ul {
            margin: 0;
            padding: 8px;
            display: block;
            background: var(--color-base);
            li {
              display: block;
              a {
                padding: 4px 13px;
                color: $primary;
                &:hover {
                  opacity: 1;
                  background: var(--color-base-100);
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }
    }
    li.active {
      a {
        font-weight: bold;
      }
    }
  }
}

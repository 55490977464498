@import "variables";

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// @import 'bootstrap/root';
@import "bootstrap/reboot";
@import "bootstrap/grid";
// @import 'bootstrap/type';
@import "bootstrap/images";
// @import 'bootstrap/code';
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
// @import "bootstrap/transitions";
// @import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
// @import "bootstrap/custom-forms";
// @import "bootstrap/nav";
// @import "bootstrap/navbar";
// @import "bootstrap/card";
// @import "bootstrap/breadcrumb";
// @import "bootstrap/pagination";
@import "bootstrap/badge";
// @import "bootstrap/jumbotron";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
// @import "bootstrap/media";
// @import "bootstrap/list-group";
// @import "bootstrap/close";
// @import "bootstrap/toasts";
// @import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
// @import "bootstrap/carousel";
// @import "bootstrap/spinners";
@import "bootstrap/utilities";
// @import "bootstrap/print";

// Theme CSS
@import "theme/type";
@import "theme/content";
@import "theme/syntax";
@import "theme/strip";
@import "theme/header";
@import "theme/footer";
@import "theme/logo";
@import "theme/dark-mode-switcher";
@import "theme/main-menu";
@import "theme/main-menu-mobile";
@import "theme/grid";
@import "theme/title";
@import "theme/hero";
@import "theme/info";
@import "theme/sidebar";
@import "theme/social";
@import "theme/breadcrumbs";
@import "theme/pagination";
@import "theme/partners";
@import "theme/author";
@import "theme/card";
@import "theme/card-team";
@import "theme/card-work";

// Font Awesome
$fa-font-path: "../fonts/fontawesome-free-5.15.3-web/webfonts";
@import "libraries/fontawesome-free-5.15.3-web/fontawesome.scss";
@import "libraries/fontawesome-free-5.15.3-web/solid.scss";
@import "libraries/fontawesome-free-5.15.3-web/regular.scss";
@import "libraries/fontawesome-free-5.15.3-web/brands.scss";
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: $font-family-base;
  font-size: 16px;
  color: var(--color-base-900);
  background: var(--color-base);
  transition: background-color 0.3s;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.page {
  .wrapper {
    flex: 1;
  }
}
.container {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}
